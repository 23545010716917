.blink {
    display: flex;
    position: absolute;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    transition: .2s ease;
    // backdrop-filter: blur(10px);
}
.blink-hidden {
    transition: .2s ease;
    opacity: 0;
    z-index: 0;
}
.blink-text {
    position: fixed;
    top: 42%;
    left: 45%;
}

.rigthSvgBg{
    position: absolute;
    width: 350px;
    height: 350px;
    left: 910px;
    top: 40px;
}

.leftSvgBg{
    display: flex;
    height: 100px;
    width: 200px;
    position: absolute;
    z-index: 1;
    top: 470px;
    left: -190px;
}
@include media( '<medium' ) {

	.rigthSvgBg, .leftSvgBg {
        display: none;
	}
}