.clients {

	.section-inner {
		padding-top: $clients--padding-t__mobile;
		padding-bottom: $clients--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $clients-header--padding__mobile;
	}
}

.clients-inner {

	ul {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0;
	}

	li {
		padding: $clients-items--padding-v $clients-items--padding-h;
	}
}
.cube-pricing{
	position: absolute;
	z-index: 100;
	right: -250px;
	top: 155px;
}
.contact-us-form{
	display: flex;
	flex-direction: column;
	max-width: 80%;
}

.required{
	color: red;
	font-size: 15px;
	margin-left: 3px;
	margin-top: -5px;
}
@include media( '>medium' ) {

	.clients {

		.section-inner {
			padding-top: $clients--padding-t__desktop;
			padding-bottom: $clients--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $clients-header--padding__desktop;
		}

		.clients-inner {

			ul {
				flex-direction: row;
			}
		}
	}
}

.foldtr {
	position: relative;
	-webkit-box-shadow: -5px 7px 5px rgba(0,0,0,0.8);
	-moz-box-shadow: -5px 7px 5px rgba(0,0,0,0.8);
	box-shadow: -5px 7px 5px rgba(0,0,0,0.8);
  }
   
  .foldtr:before {
	content: "";
	position: absolute;
	top: 0%;
	right: 0%;
	width: 0px;
	height: 0px;
	border-bottom: 70px solid #eee;
	border-right: 70px solid transparent;
	-webkit-box-shadow: -7px 7px 7px rgba(0,0,0,0.3);
	-moz-box-shadow: -7px 7px 7px rgba(0,0,0,0.3);
	box-shadow: -7px 7px 7px rgba(0,0,0,0.3);
  }
   
  .foldtr:after {
	content: "";
	position: absolute;
	top: 0%;
	right: 0%;
	width: 0px;
	height: 0px;
	border-top: 69px solid #fff; 
	border-left: 69px solid transparent;
  }

.dateDoc{
	display: flex;
	position: absolute;
	bottom: 10px;
	right: 20px;
	color: #959595;
	font-size: 13px;
	padding-top: 20px;
}

.signButton{
	display: 'flex';
	padding: 5px 10px;
	background-color: #959595;
	margin-right: 10px;
	border-radius: 5px;
}

.iconBox{
	display: flex;
	position: absolute;
	right: -50px;
}